export const CardSchema = ({ card }) => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: card.name,
    description: `${card.name} - Magic: The Gathering proxy card`,
    image: card.image_uris?.normal || card.image_uris?.large,
    category: 'Trading Card Game/Magic: The Gathering/Proxy',
    offers: {
      '@type': 'Offer',
      availability: 'https://schema.org/InStock',
      price: '0',
      priceCurrency: 'USD',
      itemCondition: 'https://schema.org/NewCondition',
    },
    additionalProperty: [
      {
        '@type': 'PropertyValue',
        name: 'Card Type',
        value: card.type_line,
      },
      {
        '@type': 'PropertyValue',
        name: 'Mana Cost',
        value: card.mana_cost,
      },
      {
        '@type': 'PropertyValue',
        name: 'Set',
        value: card.set_name,
      },
    ],
  };

  return <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />;
};
