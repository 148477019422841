import { getAlternateLanguageLinks } from '@/config/languages';
import { useTranslation } from '@/hooks/useTranslation';
import Head from 'next/head';

// Base website data that doesn't change
const WEBSITE_DATA = {
  name: 'MTG Proxies',
  alternateName: 'Magic: The Gathering Proxies',
  url: 'https://www.mtg-proxies.com',
  searchTemplate: 'https://www.mtg-proxies.com/search?s={search_term_string}',
  searchFeatures: {
    sorting: ['name'],
    languages: ['en', 'es', 'fr', 'de', 'it', 'pt', 'ja', 'ko', 'ru', 'zhs'],
  },
};

export const SEO = ({ title, description, image, fullImageUrl, url, type = 'website', publishedTime, noindex = false, translationKey, breadcrumbs = [] }) => {
  const { t, locale } = useTranslation();
  const baseUrl = WEBSITE_DATA.url;
  const fullUrl = url ? `${baseUrl}${url}` : baseUrl;
  const fullImage = fullImageUrl ? fullImageUrl : image ? `${baseUrl}${image}` : `${baseUrl}/share-image-twitter.png`;
  const alternateLinks = getAlternateLanguageLinks(url || '');

  // If a translation key is provided, use it to get translated title and description
  const translatedTitle = translationKey ? t(`${translationKey}.title`) : title;
  const translatedDescription = translationKey ? t(`${translationKey}.description`) : description;

  // Generate Schema.org structured data
  const schemaData = {
    '@context': 'https://schema.org',
    '@graph': [
      // Website entity with enhanced search metadata
      {
        '@type': 'WebSite',
        '@id': baseUrl,
        url: baseUrl,
        name: WEBSITE_DATA.name,
        alternateName: WEBSITE_DATA.alternateName,
        potentialAction: [
          {
            '@type': 'SearchAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate: WEBSITE_DATA.searchTemplate,
              contentType: ['application/x-www-form-urlencoded', 'text/plain'],
            },
            'query-input': 'required name=search_term_string',
            // Add search result features
            result: {
              '@type': 'SearchResultsPage',
              about: {
                '@type': 'Thing',
                description: 'Search results for Magic: The Gathering cards, sets, tokens, emblems, decks, and articles',
              },
            },
          },
        ],
        // Add search features metadata
        availableLanguage: WEBSITE_DATA.searchFeatures.languages,
        applicationSuite: {
          '@type': 'SoftwareApplication',
          name: 'MTG Proxies Search',
          applicationCategory: 'Search Tool',
          featureList: ['Advanced card filtering', 'Multi-language support', 'Price comparison', 'Deck building integration', 'High-resolution card images'],
        },
      },
      // Current page
      {
        '@type': 'WebPage',
        '@id': fullUrl,
        url: fullUrl,
        name: translatedTitle,
        description: translatedDescription,
        inLanguage: locale,
        isPartOf: { '@id': baseUrl },
        primaryImageOfPage: {
          '@type': 'ImageObject',
          '@id': fullImage,
          url: fullImage,
        },
        datePublished: publishedTime || new Date().toISOString(),
        dateModified: new Date().toISOString(),
      },
    ],
  };

  // Add BreadcrumbList schema if breadcrumbs are provided
  if (breadcrumbs.length > 0) {
    schemaData['@graph'].push({
      '@type': 'BreadcrumbList',
      '@id': `${fullUrl}#breadcrumb`,
      itemListElement: breadcrumbs.map((item, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@type': 'WebPage',
          '@id': item.url ? `${baseUrl}${item.url}` : baseUrl,
          url: item.url ? `${baseUrl}${item.url}` : baseUrl,
          name: item.text,
        },
      })),
    });
  }

  // Add Article schema for blog posts and articles
  if (type === 'article') {
    schemaData['@graph'].push({
      '@type': 'Article',
      '@id': `${fullUrl}#article`,
      isPartOf: { '@id': fullUrl },
      headline: translatedTitle,
      description: translatedDescription,
      image: fullImage,
      datePublished: publishedTime || new Date().toISOString(),
      dateModified: new Date().toISOString(),
      inLanguage: locale,
    });
  }

  return (
    <Head>
      <title>{translatedTitle}</title>
      <meta name='description' content={translatedDescription} />

      {noindex && <meta name='robots' content='noindex,nofollow' />}

      <meta property='og:title' content={translatedTitle} />
      <meta property='og:description' content={translatedDescription} />
      <meta property='og:image' content={fullImage} />
      <meta property='og:url' content={fullUrl} />
      <meta property='og:type' content={type} />
      <meta property='og:locale' content={locale} />
      {publishedTime && <meta property='article:published_time' content={publishedTime} />}

      <meta name='twitter:title' content={translatedTitle} />
      <meta name='twitter:description' content={translatedDescription} />
      <meta name='twitter:image' content={fullImage} />
      <meta name='twitter:card' content='summary_large_image' />

      {/* Add search-specific meta tags */}
      <meta name='search-sorting' content={WEBSITE_DATA.searchFeatures.sorting.join(',')} />

      <link rel='canonical' href={fullUrl} />

      {alternateLinks.map((link) => (
        <link key={link.hrefLang} rel='alternate' hrefLang={link.hrefLang} href={link.href} />
      ))}
      <link rel='alternate' hrefLang='x-default' href={`${baseUrl}${url || ''}`} />

      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(schemaData),
        }}
      />
    </Head>
  );
};
