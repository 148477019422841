export const DeckSchema = ({ deck }) => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    name: deck.name,
    description: deck.description || `${deck.name} - Magic: The Gathering deck list`,
    numberOfItems: deck.cards.length,
    itemListElement: deck.cards.map((card, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@type': 'Product',
        name: card.name,
        description: `${card.name} - Magic: The Gathering proxy card`,
        image: card.image_uris?.normal || card.image_uris?.large,
        category: 'Trading Card Game/Magic: The Gathering/Proxy',
        additionalProperty: [
          {
            '@type': 'PropertyValue',
            name: 'Card Type',
            value: card.type_line,
          },
          {
            '@type': 'PropertyValue',
            name: 'Quantity',
            value: card.quantity,
          },
        ],
      },
    })),
  };

  return <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />;
};
