export const ArticleSchema = ({ article }) => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: article.title,
    description: article.description,
    image: article.image,
    datePublished: article.datetime,
    dateModified: article.datetime,
    author: {
      '@type': 'Organization',
      name: 'MTG Proxies',
      url: 'https://www.mtg-proxies.com',
    },
    publisher: {
      '@type': 'Organization',
      name: 'MTG Proxies',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.mtg-proxies.com/logo.png',
      },
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `https://www.mtg-proxies.com${article.href}`,
    },
  };

  return <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />;
};
