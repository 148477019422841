import { useTranslation } from '@/hooks/useTranslation';
import { useMutation } from '@tanstack/react-query';
import { CheckIcon, Sparkles } from 'lucide-react';
import Link from 'next/link';
import { useUserStore } from '../hooks/useUser';
import { Badge } from './ui/badge';
import { Button } from './ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from './ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';

interface Feature {
  name: string;
  enabled: boolean;
}

interface PriceInfo {
  monthly: number;
  yearly: number;
}

export default function PricingSection() {
  const { user, customerDetails } = useUserStore();
  const { t } = useTranslation();
  const subMutation = useMutation({
    mutationFn: async ({ period }: { period: 'month' | 'year' }) => {
      const response = await fetch('/api/checkout', {
        method: 'POST',
        body: JSON.stringify({ period, email: user?.email }),
      });

      if (!response.ok) {
        throw new Error('Failed to subscribe');
      }

      return response.json();
    },
    onSuccess: (data) => {
      window.location.href = data.checkoutSessionUrl;
    },
  });

  const manageSubscriptionUrl = user?.email ? `${process.env.NEXT_PUBLIC_STRIPE_CUSTOMER_PORTAL_URL}?prefilled_email=${user.email}` : '';
  const pricing: PriceInfo = {
    monthly: 5,
    yearly: 40,
  };

  const features: Feature[] = [
    { name: t('pro.features.noAds'), enabled: true },
    { name: t('pro.features.darkMode'), enabled: true },
    { name: t('pro.features.advancedSearch'), enabled: true },
    { name: t('pro.features.unlimitedDecks'), enabled: true },
    { name: t('pro.features.futureFeatures'), enabled: true },
    { name: t('pro.features.cancelAnytime'), enabled: true },
    { name: t('pro.features.prioritySupport'), enabled: true },
  ];

  if (process.env.NEXT_PUBLIC_PRO_ACCOUNTS_LIVE !== 'true') {
    return null;
  }

  const calculateSavings = () => {
    const monthlyTotal = pricing.monthly * 12;
    const yearlySavings = monthlyTotal - pricing.yearly;
    const savingsPercentage = Math.round((yearlySavings / monthlyTotal) * 100);
    return { amount: yearlySavings, percentage: savingsPercentage };
  };

  const savings = calculateSavings();

  const renderPlanCard = (period: 'month' | 'year', features: Feature[]) => (
    <Card className={`w-full max-w-md mx-auto relative transition-all duration-300 hover:shadow-lg ${period === 'year' ? 'border-green-500/20' : ''}`}>
      {period === 'year' && (
        <div className='absolute -top-3 left-0 right-0 flex justify-center'>
          <Badge variant='primary' className='shadow-sm'>
            <Sparkles className='w-4 h-4 mr-1' />
            Save ${savings.amount}/year ({savings.percentage}% off)
          </Badge>
        </div>
      )}
      <CardHeader>
        <CardTitle className='text-xl'>{period === 'month' ? t('pro.pricing.monthlyPlan') : t('pro.pricing.yearlyPlan')}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className='flex flex-col gap-6'>
          <div className='flex flex-col'>
            <div className='flex items-baseline gap-2'>
              <span className='text-4xl font-bold'>${period === 'month' ? pricing.monthly : pricing.yearly}</span>
              <span className='text-muted-foreground'>/{period}</span>
            </div>
            {period === 'year' && <p className='text-sm text-muted-foreground mt-1'>${(pricing.yearly / 12).toFixed(2)}/month when billed annually</p>}
          </div>
          <ul className='grid gap-3'>
            {features.map((feature: Feature) => (
              <li key={feature.name} className='flex items-center gap-3 text-sm'>
                <span className='flex-shrink-0 rounded-full p-1 bg-green-100 text-green-800'>
                  <CheckIcon className='h-4 w-4' />
                </span>
                {feature.name}
              </li>
            ))}
          </ul>
        </div>
      </CardContent>
      <CardFooter>
        {user ? (
          <>
            {customerDetails?.proMemberStatus === 'active' ? (
              <Button className='w-full' asChild>
                <Link href={manageSubscriptionUrl} target='_blank'>
                  {customerDetails?.interval === period ? t('pro.pricing.manageSubscription') : period === 'month' ? t('pro.pricing.subscribeMonthly') : t('pro.pricing.subscribeYearly')}
                </Link>
              </Button>
            ) : (
              <Button
                disabled={subMutation.isPending}
                variant={period === 'month' ? 'primary' : 'default'}
                className={`w-full ${period === 'year' ? 'bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white' : ''}`}
                onClick={() => subMutation.mutate({ period })}
              >
                {period === 'month' ? t('pro.pricing.subscribeMonthly') : t('pro.pricing.subscribeYearly')}
              </Button>
            )}
          </>
        ) : (
          <Button className='w-full' asChild variant={period === 'month' ? 'primary' : 'default'}>
            <Link href='/login'>{t('pro.pricing.signInToSubscribe')}</Link>
          </Button>
        )}
      </CardFooter>
    </Card>
  );

  return (
    <section className='w-full py-12 md:py-24 lg:py-32 bg-background'>
      <div className='container px-4 md:px-6'>
        <div className='flex flex-col items-center justify-center space-y-4 text-center'>
          <div className='space-y-3'>
            <div className='mt-10 mb-6'>
              <Badge variant='primary'>
                {t('common.proMembership')}
              </Badge>
            </div>
            <div className='space-y-2'>
              <h2 className='text-3xl font-bold tracking-tighter sm:text-5xl'>{t('pro.pricing.title')}</h2>
              <p className='mx-auto max-w-[600px] text-muted-foreground md:text-xl'>{t('pro.pricing.subtitle')}</p>
            </div>
          </div>
        </div>

        <div className='mt-8'>
          <Tabs defaultValue='month' className='w-full'>
            <div className='flex justify-center mb-8'>
              <TabsList className='grid w-[300px] grid-cols-2'>
                <TabsTrigger value='month' className='text-sm'>
                  Monthly
                </TabsTrigger>
                <TabsTrigger value='year' className='text-sm'>
                  Yearly • Best Value
                </TabsTrigger>
              </TabsList>
            </div>

            <TabsContent value='month' className='mt-0'>
              {renderPlanCard('month', features)}
            </TabsContent>

            <TabsContent value='year' className='mt-0'>
              {renderPlanCard('year', features)}
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </section>
  );
}
