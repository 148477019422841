import { CircleX, Moon, Search, Sparkles, Star } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { useTranslation } from '@/hooks/useTranslation';
import { cn } from '@/lib/utils';

export default function MembershipBenefits() {
  const { t } = useTranslation();

  const benefits = [
    {
      title: t('pro.benefits.noAds.title'),
      description: t('pro.benefits.noAds.description'),
      icon: <CircleX className='w-6 h-6 text-rose-500' />,
      gradient: 'from-rose-50 via-rose-100/50 to-transparent dark:from-rose-950/20 dark:via-rose-900/10 dark:to-transparent',
      iconBg: 'bg-rose-100 dark:bg-rose-900/20',
    },
    {
      title: t('pro.benefits.darkMode.title'),
      description: t('pro.benefits.darkMode.description'),
      icon: <Moon className='w-6 h-6 text-purple-500' />,
      gradient: 'from-purple-50 via-purple-100/50 to-transparent dark:from-purple-950/20 dark:via-purple-900/10 dark:to-transparent',
      iconBg: 'bg-purple-100 dark:bg-purple-900/20',
    },
    {
      title: t('pro.benefits.advancedSearch.title'),
      description: t('pro.benefits.advancedSearch.description'),
      icon: <Search className='w-6 h-6 text-blue-500' />,
      gradient: 'from-blue-50 via-blue-100/50 to-transparent dark:from-blue-950/20 dark:via-blue-900/10 dark:to-transparent',
      iconBg: 'bg-blue-100 dark:bg-blue-900/20',
    },
    {
      title: t('pro.benefits.multipleDecks.title'),
      description: t('pro.benefits.multipleDecks.description'),
      icon: <Sparkles className='w-6 h-6 text-amber-500' />,
      gradient: 'from-amber-50 via-amber-100/50 to-transparent dark:from-amber-950/20 dark:via-amber-900/10 dark:to-transparent',
      iconBg: 'bg-amber-100 dark:bg-amber-900/20',
    },
    {
      title: t('pro.benefits.futureFeatures.title'),
      description: t('pro.benefits.futureFeatures.description'),
      icon: <Star className='w-6 h-6 text-emerald-500' />,
      gradient: 'from-emerald-50 via-emerald-100/50 to-transparent dark:from-emerald-950/20 dark:via-emerald-900/10 dark:to-transparent',
      iconBg: 'bg-emerald-100 dark:bg-emerald-900/20',
    },
    {
      title: t('pro.benefits.cancelAnytime.title'),
      description: t('pro.benefits.cancelAnytime.description'),
      icon: <CircleX className='w-6 h-6 text-indigo-500' />,
      gradient: 'from-indigo-50 via-indigo-100/50 to-transparent dark:from-indigo-950/20 dark:via-indigo-900/10 dark:to-transparent',
      iconBg: 'bg-indigo-100 dark:bg-indigo-900/20',
    },
    {
      title: t('pro.benefits.prioritySupport.title'),
      description: t('pro.benefits.prioritySupport.description'),
      icon: <Sparkles className='w-6 h-6 text-teal-500' />,
      gradient: 'from-teal-50 via-teal-100/50 to-transparent dark:from-teal-950/20 dark:via-teal-900/10 dark:to-transparent',
      iconBg: 'bg-teal-100 dark:bg-teal-900/20',
    },
  ];

  return (
    <div className='max-w-6xl mx-auto px-4 pt-16 pb-24 lg:pb-32'>
      <div className='text-center space-y-4 mb-16'>
        <h2 className='text-4xl font-bold tracking-tight mb-4'>{t('pro.benefits.title')}</h2>
        <p className='text-gray-600 dark:text-gray-300 text-lg max-w-2xl mx-auto'>
          {t('pro.benefits.subtitle')}
        </p>
      </div>

      <div className='grid gap-6 md:grid-cols-2 lg:grid-cols-3'>
        {benefits.map((benefit, index) => (
          <Card
            key={benefit.title}
            className={cn(
              'relative overflow-hidden backdrop-blur-sm transition-all duration-300',
              'hover:shadow-lg hover:scale-[1.02]',
              'border border-gray-200/50 dark:border-gray-800/50'
            )}
          >
            <div className={cn('absolute inset-0 bg-gradient-to-br opacity-40', benefit.gradient)} />
            <CardHeader>
              <CardTitle className='flex items-center gap-4 text-xl'>
                <div className={cn(
                  'p-2.5 rounded-xl shadow-sm',
                  benefit.iconBg,
                  'ring-1 ring-gray-200/50 dark:ring-gray-800/50'
                )}>
                  {benefit.icon}
                </div>
                <span className='relative'>{benefit.title}</span>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className='text-gray-600 dark:text-gray-300 text-base leading-relaxed relative'>
                {benefit.description}
              </p>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
}
